<template>
  <div>
    <!-- Back button  if u want to use this breadcrumbs just remove the hidden class -->
    <div class="heading-sec">
      <div class="row">
        <div class="col-md-4 column">
          <div class="heading-profile">
            <div class="font-24 mt-10 ml-4">Edit Product</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Top Bar Chart -->
    <v-card class="mt-2 mx-4">
      <div class="panel-content">
        <div class="row">
          <div class="col-md-12">
            <div class="widget">
              <div class="form-elements-sec">
                <div class="form-body">
                  <form
                    name="details"
                    class="formPart text-left"
                    @submit.prevent="submitForm(details)"
                  >
                    <!-- Email -->
                    <div align="center">
                      <div
                        class="form-group col input-section"
                        :class="{
                          'form-group--error': $v.details.email.$error,
                        }"
                      >
                        <label for="email">Email:</label>

                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          placeholder="name@gmail.com"
                          v-model.trim="details.email"
                          @input="setTouch('email')"
                        />
                        <div
                          class="validation"
                          v-if="
                            !$v.details.email.required &&
                            $v.details.email.$error
                          "
                        >
                          Email is required
                        </div>
                      </div>
                    </div>
                    <div class="pt-7" align="center">
                      <span class="font-18">Product Linesheet</span>

                      <v-card class="select-file-card mt-2">
                        <div
                          class="flex items-center justify-center text-center"
                        >
                          <div class="pt-4 rakesh">
                            <v-file-input
                              id="assetsFieldHandle"
                              @change="uploadExcelSheet"
                              accept=".xlsx, .xls, .csv"
                              v-model="details.excelSheet"
                            />
                            <div
                              class="validation"
                              v-if="
                                !$v.details.excelSheet.required &&
                                $v.details.excelSheet.$error
                              "
                            >
                              Excel Sheet is required
                            </div>

                            <!-- <label for="assetsFieldHandle">
                              <div class="font-14 pt-3">
                                Drag files to upload OR
                                <span
                                  class="
                                    font-14
                                    text-maroon text-decoration-underline
                                  "
                                  >Select File</span
                                >
                              </div>
                              <ul v-if="this.filelist" v-cloak>
                                <div
                                  class="text-sm p-1"
                                  v-for="(file, index) in filelist"
                                  :key="index"
                                >
                                  {{ file.name
                                  }}<button
                                    type="button"
                                    @click="remove(filelist.indexOf(file))"
                                    title="Remove file"
                                  ></button>
                                </div>
                              </ul>
                            </label> -->
                          </div>
                        </div>
                      </v-card>
                      <div class="btnPart text-center my-4">
                        <button type="submit" class="btn btn-success">
                          Submit
                        </button>
                        <p class="typo__p" v-if="submitStatus === 'OK'">
                          Thanks for your submission!
                        </p>
                        <p class="typo__p" v-if="submitStatus === 'ERROR'">
                          Please fill the form correctly.
                        </p>

                        <button
                          type="reset"
                          class="btn btn-danger mx-3"
                          v-on:click="resetForm()"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>

                    <!-- Linesheet table -->
                    <div
                      v-if="sheetErrors"
                      class="justify-center"
                      align="center"
                    >
                      <div
                        class="font-22 butler-medium text-center mt-6 mt-md-10"
                      >
                        Linesheet Details
                      </div>
                      <v-simple-table class="mt-6 table-section">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th
                                class="
                                  text-left
                                  noto-serif-bold
                                  font-12
                                  text-maroon
                                "
                                width="80px"
                              >
                                Sr.No
                              </th>
                              <th
                                class="
                                  text-left
                                  noto-serif-bold
                                  text-maroon
                                  font-12
                                "
                              >
                                Error Location
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, idx) in sheetErrors" :key="idx">
                              <td>{{ idx + 1 }}</td>
                              <td class="text-red">
                                Error - {{ item.errorInParent[0].srNo }}
                                <span
                                  v-for="(cell, idx) in item.errorInParent[0]
                                    .cellDetails"
                                  :key="idx"
                                >
                                  {{ cell
                                  }}<span
                                    v-if="
                                      item.errorInParent[0].cellDetails.length >
                                        1 &&
                                      idx !=
                                        item.errorInParent[0].cellDetails
                                          .length -
                                          1
                                    "
                                    >,
                                  </span>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import productService from "../../services/productService";
import fileUploadService from "../../services/fileUploadService";

export default {
  delimiters: ["${", "}"],
  data() {
    return {
      details: {
        email: "",
        excelSheet: "",
      },
      submitStatus: null,
      sheetErrors: [],
      filelist: "",
      userType: "Customer",
      uploadedUrl: "",
    };
  },

  validations: {
    details: {
      email: {
        required,
        email,
      },
      excelSheet: {
        required,
      },
    },
  },

  methods: {
    // this method use for validation error

    async submitForm(details) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
        this.$store.commit("setSnackbar", {
          content: "please fill form correctly",
          icon: "mdi-alert-circle",
          color: "error ",
          isVisible: true,
        });
      } else {
        const data = {};

        data.email = this.details.email;
        data.url = this.uploadedUrl;
        const result = await productService.updateProduct(data);

        if (result.status === 200) {
          this.sheetErrors = result.data.error;
          this.$store.commit("setSnackbar", {
            content: "Submitted Successfully",
            icon: "mdi-check-circle",
            color: "success ",
            isVisible: true,
          });
        } else {
          console.log(result.data.error, "error here");
          this.$store.commit("setSnackbar", {
            content: "Something went wrong !",
            icon: "mdi-alert-circle",
            color: "error ",
            isVisible: true,
          });
        }
      }
    },

    async uploadExcelSheet(file) {
      console.log("File: ", file);
      const result = await fileUploadService.uploadFile(file);
      if (result.status == 200) {
        this.uploadedUrl = result.data.file;
        console.log("Sheet: ", this.excelSheet);
      }
    },
    // this method use for cancel button
    resetForm() {
      this.details.email = "";
      this.details.excelSheet = "";
      this.sheetErrors = [];
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    // onChange() {
    //   this.filelist = [...this.$refs.file.files];
    // },
    // dragover(event) {
    //   event.preventDefault();
    //   // Add some visual fluff to show the user can drop its files
    //   if (!event.currentTarget.classList.contains("bg-green-300")) {
    //     event.currentTarget.classList.remove("bg-gray-100");
    //     event.currentTarget.classList.add("bg-green-300");
    //   }
    // },
    // dragleave(event) {
    //   // Clean up
    //   event.currentTarget.classList.add("bg-gray-100");
    //   event.currentTarget.classList.remove("bg-green-300");
    // },
    // drop(event) {
    //   event.preventDefault();
    //   this.$refs.file.files = event.dataTransfer.files;
    //   this.onChange(); // Trigger the onChange event manually
    //   // Clean up
    //   event.currentTarget.classList.add("bg-gray-100");
    //   event.currentTarget.classList.remove("bg-green-300");
    // },
  },
};
</script>
<style lang="scss" scoped>
.validation {
  color: red;
}
.select-file-card {
  height: 80px;
  width: 320px;
  border: 1px solid #646464 !important;
  border-radius: 10px !important;
  box-shadow: none !important;
}
#assetsFieldHandle {
  width: 0;
  height: 0;
}
.input-section {
  width: 40% !important;
}
.table-section {
  width: 40% !important;
}
</style>
